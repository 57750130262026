<template>
  <div data-testid="vonage-home-page-banner-section" class="max-h-[160px] bg-[#fde0d8]" :class="{ hidden }">
    <SectionWithMaxWidth>
      <div class="flex flex-row">
        <div class="hidden lg:block flex-grow" />
        <div class="flex gap-x-4 justify-center items-center" @click="trackAnchorClick">
          <VIcon :name="blok.icon || 'megaphone-line'" :size="-5" />

          <RichText class="color-neutral-900 text-bold" :text="blok.text" />
        </div>
        <div class="flex-grow" />
        <div class="items-center ml-4 hidden laptop:flex">
          <div
            class="flex items-center p-2 cursor-pointer rounded-md hover:bg-black/5 active:text-purple-600"
            @click="hideBanner"
          >
            <VIcon name="close-solid" />
          </div>
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import { VIcon } from '@vonage/vivid-vue';
import RichText from '@/components/rich-text/RichText.vue';
import { computed, type PropType, type Ref, ref } from 'vue';
import type { VonageHomePageBannerSection } from '@/types/storyblok';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import { useCookie } from 'nuxt/app';
import { MaxAgeForCookie, VonageHomePageBannerAnalyticsInteractionType } from '@/constants';
import md5 from 'md5';
import { trackEngagementRule, updateInteractionType } from '@/utils/analytics/adobe';

const homePageBannerLink = ref('');
const props = defineProps({
  blok: { type: Object as PropType<VonageHomePageBannerSection>, required: true },
});

// generate short ID using MD5
const id = computed(() => md5(`${props.blok?.icon}-${props.blok?.text}`));

// hide banner by adding it to a cookie
const hiddenBanners: Ref<string[]> = useCookie('hiddenBanners', {
  default: () => [],
  maxAge: MaxAgeForCookie,
});
const hidden = computed(() => hiddenBanners.value.includes(id.value));
const hideBanner = () => {
  hiddenBanners.value = [...hiddenBanners.value, id.value];
};

const trackAnchorClick = event => {
  if (event.target.tagName.toLowerCase() === 'a') {
    homePageBannerLink.value = event.target.getAttribute('href');
    updateInteractionType(VonageHomePageBannerAnalyticsInteractionType);
    trackEngagementRule();
  }
};
</script>
